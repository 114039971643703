<template>
    <button class="cr-button" :type="type" :disabled="disabled || loading" :class="buttonClasses">
        <Vue3Lottie :animationData="animationSpin" class="animation-icon" v-if="loading" />
        <div class="cr-button__icon" :class="{ 'cr-button__icon_last': iconLast }" v-if="$slots['icon']">
            <slot name="icon" />
        </div>
        <input v-if="upload" class="image-input" type="file" @change="$emit('handleUpload', $event)" />
        <slot />
        <CrossSvg v-if="close" />
        <PenSvg v-if="edit" />
        <MinusSvg v-if="minus" />
        <PlusSvg v-if="plus || addToCart" />
        <CheckSvg v-if="plainChecked" />
        <ShareSvg v-if="share" />
        <FacebookSvg v-if="facebook" />
        <TwitterSvg v-if="twitter" />
        <CopySvg v-if="copy" />
        <CheckedOrange class="check-icon" v-if="checked" />
        <div class="added-in-cart" v-if="addToCart && active">
            <AddedToCartSvg />
        </div>
    </button>
</template>

<script>
    import { Vue3Lottie } from 'vue3-lottie';
    import CrossSvg from '@/assets/icons/buttons/cross-black.svg?inline';
    import AddedToCartSvg from '@/assets/icons/circles/circle-added-to-cart.svg?inline';
    import PenSvg from '@/assets/icons/buttons/pen-white.svg?inline';
    import MinusSvg from '@/assets/icons/buttons/minus-black.svg?inline';
    import PlusSvg from '@/assets/icons/buttons/plus-white.svg?inline';
    import ShareSvg from '@/assets/icons/buttons/share-black.svg?inline';
    import FacebookSvg from '@/assets/icons/buttons/facebook-black.svg?inline';
    import TwitterSvg from '@/assets/icons/buttons/twitter-black.svg?inline';
    import CopySvg from '@/assets/icons/buttons/copy-black.svg?inline';
    import SpinWhite from '@/assets/animations/spin-white.json';
    import SpinPrimary from '@/assets/animations/spin-primary.json';
    import CheckedOrange from '@/assets/icons/checks/checked-orange.svg?inline';
    import CheckSvg from '@/assets/icons/checks/check-icon.svg?inline';

    export default {
        name: 'CRButton',
        components: {
            CrossSvg,
            PenSvg,
            MinusSvg,
            PlusSvg,
            ShareSvg,
            AddedToCartSvg,
            Vue3Lottie,
            CheckedOrange,
            FacebookSvg,
            CheckSvg,
            TwitterSvg,
            CopySvg,
        },
        props: {
            size: {
                type: String,
                default: 'base',
                validator: (value) => value.match(/(xx-small|x-small|small|base)/),
            },
            pattern: {
                type: String,
                default: 'fill',
                validator: (value) => value.match(/(fill|outline|thin|section|toggle)/),
            },
            color: {
                type: String,
                default: 'primary',
                validator: (value) => value.match(/(primary|secondary|danger)/),
            },
            type: {
                type: String,
                default: 'submit',
                validator: (value) => value.match(/(button|reset|submit)/),
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            fixed: {
                type: Boolean,
                default: false,
            },
            active: {
                type: Boolean,
                default: false,
            },
            close: {
                type: Boolean,
                default: false,
            },
            edit: {
                type: Boolean,
                default: false,
            },
            minus: {
                type: Boolean,
                default: false,
            },
            plus: {
                type: Boolean,
                default: false,
            },
            share: {
                type: Boolean,
                default: false,
            },
            copy: {
                type: Boolean,
                default: false,
            },
            twitter: {
                type: Boolean,
                default: false,
            },
            facebook: {
                type: Boolean,
                default: false,
            },
            iconLast: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            addToCart: {
                type: Boolean,
                default: false,
            },
            checked: {
                type: Boolean,
                default: false,
            },
            upload: {
                type: Boolean,
                default: false,
            },
            plainChecked: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['handleUpload'],
        computed: {
            buttonClasses() {
                return [
                    `cr-button_size-${this.size}`,
                    `cr-button_${this.pattern}-${this.color}`,
                    this.disabled && `cr-button_disabled cr-button_${this.pattern}-disabled`,
                    this.fixed && `cr-button_fixed`,
                    this.active && `cr-button_active`,
                    this.close && `cr-button_close`,
                    (this.edit || this.plainChecked) && `cr-button_edit`,
                    this.minus && `cr-button_minus`,
                    this.plus && `cr-button_plus`,
                    (this.share || this.copy || this.twitter || this.facebook) && `cr-button_share`,
                    this.loading && `cr-button_loading`,
                    this.addToCart && `cr-button_add-to-cart`,
                    this.checked && `cr-button_checked`,
                    this.upload && `cr-button_upload`,
                ];
            },
            animationSpin() {
                return this.pattern === 'fill' ? SpinWhite : SpinPrimary;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .cr-button {
        @include row-align-center-justify-center;

        border-radius: $br-64;
        border: 2px solid transparent;

        color: $white;
        font-weight: bold;
        font-size: $font-14;
        line-height: $line-height-body-150;

        transition: all 0.2s linear;

        position: relative;
    }

    // loading animation
    .animation-icon {
        position: absolute;
        top: 5px;
        left: -10px;
    }

    .cr-button_loading {
        cursor: default;
        pointer-events: none;
        padding-left: 50px !important;
    }

    // size
    .cr-button_size-base {
        padding: 14px 32px;
    }

    .cr-button_size-small {
        padding: 8px 24px;
    }

    .cr-button_size-x-small {
        padding: 8px 10px;
    }

    .cr-button_size-xx-small {
        padding: 4px 14px;
    }

    // icon
    .cr-button__icon {
        @include row-align-center-justify-center;

        margin-right: 5px;
    }

    .cr-button__icon_last {
        margin: 0 5px 0;

        order: 2;
    }

    // fill
    .cr-button_fill-primary {
        color: $white;
        background: $primary;

        &:hover {
            color: $primary;
            background: $primary-400;
            border: 2px solid $primary;
        }

        &:active {
            background: $primary-300;
        }
    }

    .cr-button_fill-danger {
        color: $white;
        background: $danger;

        &:hover {
            color: $danger;
            background: $danger-400;
            border: 2px solid $danger;
        }

        &:active {
            background: $danger-300;
        }
    }

    .cr-button_fill-secondary {
        color: $white;
        background: $secondary;

        &:hover {
            color: $secondary;
            background: $secondary-400;
            border: 2px solid $secondary;
        }

        &:active {
            background: $secondary-300;
        }
    }

    // outline
    .cr-button_outline-primary {
        color: $primary;
        background: $white;
        border: 2px solid $primary;

        &:hover {
            color: $primary;
            background: $primary-400;
        }

        &:active {
            background: $primary-300;
        }
    }

    .cr-button_outline-danger {
        color: $danger;
        background: $white;
        border: 2px solid $danger;

        &:hover {
            color: $danger;
            background: $danger-400;
        }

        &:active {
            background: $danger-300;
        }
    }

    .cr-button_outline-secondary {
        color: $secondary;
        background: $white;
        border: 2px solid $secondary;

        &:hover {
            color: $secondary;
            background: $secondary-400;
        }

        &:active {
            background: $secondary-300;
        }
    }

    // thin
    .cr-button_thin-primary {
        @include margin-padding-reset;

        color: $primary;
        background: none;

        text-decoration-line: underline;

        &:hover {
            color: $primary-200;
        }

        &:active {
            color: $primary-100;
        }
    }

    .cr-button_thin-danger {
        @include margin-padding-reset;

        color: $danger;
        background: none;

        text-decoration-line: underline;

        &:hover {
            color: $danger-200;
        }

        &:active {
            color: $danger-100;
        }
    }

    .cr-button_thin-secondary {
        @include margin-padding-reset;

        color: $secondary;
        background: none;

        text-decoration-line: underline;

        &:hover {
            color: $secondary-200;
        }

        &:active {
            color: $secondary-100;
        }
    }

    // section
    .cr-button_section-primary {
        color: $black;
        background: $white;

        border: none;
        border-radius: $br-20;
        box-shadow: $box-shadow-dark;

        padding: 10px 10px;

        &:hover {
            background: $primary-400;
        }
    }

    .cr-button_section-primary.cr-button_active {
        color: $white;
        background: $primary;
        box-shadow: none;

        cursor: default;
        pointer-events: none;

        &:hover {
            color: $white;
            background: $primary;
        }
    }

    // toggle
    .cr-button_toggle-primary {
        color: $primary;
        background: $white;

        border: 2px solid $primary;
        border-radius: $br-20;

        padding: 4px 10px;

        &:hover {
            background: $primary-500;
        }
    }

    .cr-button_toggle-primary.cr-button_active {
        background: $primary-400;
    }

    // disabled
    .cr-button_disabled {
        cursor: default;
        pointer-events: none;
    }

    .cr-button_fill-disabled {
        color: $white;
        background: $grey-disabled;
    }

    .cr-button_outline-disabled {
        color: $grey-disabled;
        background: $white;
        border: 2px solid $grey-disabled;
    }

    .cr-button_thin-disabled {
        color: $grey-disabled;
        background: none;
        text-decoration-line: underline;
    }

    // fixed
    .cr-button_fixed {
        border: 2px solid $white;
        box-shadow: $box-shadow-dark;

        &:hover,
        &:active {
            box-shadow: $box-shadow-dark;
        }
    }

    // close
    .cr-button_close {
        width: 44px;
        height: 44px;

        background: $white;
        box-shadow: $box-shadow-dark;

        border: none;
        border-radius: $br-100;

        padding: 14px;

        &:hover {
            background: $primary-300;
            border: none;
        }

        &:active {
            background: $primary-200;
        }
    }

    // edit
    .cr-button_edit {
        width: 44px;
        height: 44px;

        border: none;
        border-radius: $br-100;

        padding: 14px;

        &:hover {
            background: $primary-300;
            border: none;
        }

        &:active {
            background: $primary-200;
        }
    }

    // minus
    .cr-button_minus {
        width: 44px;
        height: 44px;

        border: none;
        border-radius: $br-100;

        background: $white;
        box-shadow: $box-shadow-dark;

        padding: 14px;

        &:hover {
            background: $primary-300;
            border: none;
        }

        &:active {
            background: $primary-200;
        }
    }

    // plus
    .cr-button_plus {
        @include row-align-center;

        width: 44px;
        height: 44px;

        border: none;
        border-radius: $br-100;

        padding: 14px;

        box-shadow: $box-shadow-dark;

        &:hover {
            background: $primary-300;
            border: none;
        }

        &:active {
            background: $primary-200;
        }
    }

    // share
    .cr-button_share {
        padding: 10px;
        padding: 0;
        width: 37px;
        height: 37px;

        box-shadow: $box-shadow-dark;
        border: none;
        border-radius: $br-100;
        background: $white;

        &:hover {
            background: $primary-300;
            border: none;
        }

        &:active {
            background: $primary-200;
        }
    }

    // add-to-cart
    .cr-button_add-to-cart {
        width: 44px;
        height: 44px;

        border: none;
        border-radius: $br-100;

        padding: 14px;

        box-shadow: $box-shadow-dark;

        background: $white;

        &:hover {
            background: $primary-300;
            border: none;

            &:deep() {
                path {
                    fill: $white;
                }
            }
        }

        &:active {
            background: $primary-200;

            &:deep() {
                path {
                    fill: $white;
                }
            }
        }

        &:deep() {
            path {
                fill: $primary;
            }
        }

        .added-in-cart {
            @include row-align-center-justify-center;

            position: absolute;
            right: -5px;
            top: -5px;

            border-radius: $br-100;
            border: 1px $white solid;

            background: $white;

            &:deep() {
                path {
                    fill: $orange;
                }
            }
        }
    }

    // checked
    .cr-button_checked {
        .check-icon {
            border-radius: $br-100;
            margin-left: 10px;
            background: $white;
        }
    }

    // cr-button_upload
    .cr-button_upload {
        position: relative;

        .image-input {
            cursor: pointer;
            position: absolute;
            opacity: 0;
        }
    }
</style>
